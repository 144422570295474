<template>
  <div class="grid">
    <div class="col-12 xl:col-12">
      <div class="card">
        <!-- INFO BAR ----------------------------------------------------------------------------------------------------------------------------------->
        <ProjectInfo />
        <!-- INFO BAR ----------------------------------------------------------------------------------------------------------------------------------->
        <!-- BODY HEADER ----------------------------------------------------------------------------------------------------------------------------------->
        <BodyHeader />
        <!-- BODY HEADER ----------------------------------------------------------------------------------------------------------------------------------->
        <!-- Accordion Tab ----------------------------------------------------------------------------------------------------------------------------------->
        <div class="grid" style="padding-top: 20px; justify-content: center">
          <div
            class="col-12 xl:col-12 sm:col-12 md:col-12 .col-12"
            style="text-align: center"
          >
            <ChartError
              :data="changes.chartData"
              :description="changes.description"
              :filterType="'basic'"
              :chartType="'pathogenic'"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProjectInfo from "@/components/ProjectInfo.vue";
import BodyHeader from "@/components/GrowthPromoters/BodyHeader.vue";
import ChartError from "@/components/Generic/ChartError.vue";
import { useProjectsStore } from "@/store/projects";
import { changes } from "@/assets/Data/growthPromotersData.js";

export default {
  data() {
    return {
      projectsStore: useProjectsStore(),
      i: 1,
      changes,
      selectedType: null,
      loading: true,
      displayModal: false,
    };
  },
  computed: {
    project() {
      var projectId = this.$route.params.projectId;
      return this.projectsStore.project(projectId);
    },
  },
  mounted() {},
  beforeUnmount() {},
  created() {},
  methods: {},
  components: {
    ProjectInfo: ProjectInfo,
    BodyHeader: BodyHeader,
    ChartError: ChartError,
  },
};
</script>

<style></style>
