export const changes = {
    description: {
      organism: "Fósforo",
    },
    chartData: {
      Fósforo: {
        bacterias: [{
      type: 'Bacillus',
      avgH: 3.1,
      avgLowT: 9.1,
      avgTighT: 19.1,
      avgT: 1.5,
    }, {
      type: 'Paenibacillus',
      avgH: 9.0,
      avgLowT: 9.8,
      avgTighT: 19.6,
      avgT: 5,
    }, {
      type: 'Streptomyces',
      avgH: 3.0,
      avgLowT: 10.6,
      avgTighT: 20.4,
      avgT: 0.9,
    }, {
      type: 'Azospirillum',
      avgH: 7,
      avgLowT: 11.9,
      avgTighT: 21.7,
      avgT: 0.6,
    }, {
      type: 'Rhizobium',
      avgH: 3.0,
      avgLowT: 13.6,
      avgTighT: 22.7,
      avgT: 0.5,
    }, {
      type: 'Bradyrhizobium',
      avgH: 5.5,
      avgLowT: 13.6,
      avgTighT: 22.7,
      avgT: 5.0,
    }, {
      type: 'Purpureocillium',
      avgH: 4.5,
      avgLowT: 13.6,
      avgTighT: 22.7,
      avgT: 0.9,
    }
    , {
      type: 'Nocardinoides',
      avgH: 7.0,
      avgLowT: 13.6,
      avgTighT: 22.7,
      avgT: 4.4,
    }],
        fungus: [{
      type: 'Trichoderma',
      avgH: 6.2,
      avgLowT: 9.1,
      avgTighT: 19.1,
      avgT: 5.5,
    }, {
      type: 'Glomus',
      avgH: 4.2,
      avgLowT: 9.8,
      avgTighT: 19.6,
      avgT: 2.1,
    },
    {
      type: 'Curvularia',
      avgH: 2.1,
      avgLowT: 9.1,
      avgTighT: 19.1,
      avgT: 1.2,
    },
    {
      type: 'Phialocephala',
      avgH: 1.3,
      avgLowT: 9.1,
      avgTighT: 19.1,
      avgT: 0.5,
    },
    ],
      },
    },
  };
  